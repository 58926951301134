// src/pages/MonarchsUK.js
import React from 'react';

function MonarchsUK() {
  return (
    <div>
      <h1>The Monarchs of the UK</h1>
      <p>Learn about the history of the United Kingdom's monarchy.</p>
    </div>
  );
}

export default MonarchsUK;
