// src/pages/GreekRomanMythology.js
import React from 'react';

function GreekRomanMythology() {
  return (
    <div>
      <h1>Greek & Roman Mythology</h1>
      <p>Explore the fascinating myths of ancient Greece and Rome.</p>
    </div>
  );
}

export default GreekRomanMythology;
